import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import NoImage from "src/assets/images/logo.png";

const Image = ({ src, width, height, alt, effect, classes = "", wrapperClasses = "", hideImageOnError = false, onError = () => {} }) => {
  const [showImage, setShowImage] = useState(true);

  if (hideImageOnError && (!src || !src.includes("http"))) return <></>;

  return (
    <>
      {showImage && (
        <LazyLoadImage
          wrapperClassName={wrapperClasses}
          src={src}
          width={width}
          height={height}
          alt={alt}
          effect={effect || "opacity"}
          className={"h-full w-full " + classes}
          onError={(e) => {
            e.target.src = NoImage;
            if (hideImageOnError) {
              setShowImage(true);
            }
            onError();
          }}
        />
      )}
    </>
  );
};

export default Image;
