import { Route, Routes } from "react-router-dom";

import FreeAuthRoute from "src/routes/FreeAuthRoute";
import ProtectedRoute from "src/routes/ProtectedRoute";

import Dashboard from "src/pages/Dashboard";
import ErrorPage from "src/pages/auth/404";
import Login from "src/pages/auth/Login";
import Logout from "src/pages/auth/Logout";
import PermissionDenied from "src/pages/auth/PermissionDenied";
import SSOLogin from "src/pages/auth/SSOLogin";
import ForgotPassword from "src/pages/forgot-password/ForgotPassword";
import ResetPassword from "src/pages/forgot-password/ResetPassword";
import RedirectToEmbed from "src/pages/RedirectToEmbed";
import AuthenticationVerify from "src/pages/forgot-password/AuthenticationVerify";
import Authorization from "src/pages/auth/authorization";

const AppRoutes = () => {
  return (
    <Routes>
      {/* NOT PROTECTED routes */}
      <Route
        path="/"
        element={<FreeAuthRoute />}>
        <Route
          index
          element={<Login />}
        />
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/sso-login"
          element={<SSOLogin />}
        />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/forgot-password/reset-password/:token"
          element={<ResetPassword type="forgot-password" />}
        />

        <Route
          path="/token/:token"
          element={<ResetPassword type="welcome-mail" />}
        />
        <Route
          path="/authentication/verify/:token"
          element={<AuthenticationVerify />}
        />
      </Route>

      {/* PROTECTED routes */}
      <Route
        exact
        path="/"
        element={<ProtectedRoute />}>
        <Route
          exact
          path="/permission-denied"
          element={<PermissionDenied />}
        />
        <Route
          exact
          path="/workspace/:workspace_id/permission-denied"
          element={<PermissionDenied />}
        />
        {/* <Route
          path="/workspace/:workspace_id"
          element={<Dashboard2 />}
        /> */}
        <Route
          path="/workspace/:workspace_id/embed"
          element={<Dashboard />}
        />
        {/* Redirection routes */}
        <Route
          path="/workspace/:workspace_id/page/:page_id"
          element={<RedirectToEmbed />}
        />
        <Route
          path="/workspace/:workspace_id/group/:group_id/page/:page_id"
          element={<RedirectToEmbed />}
        />
      </Route>
      <Route
        path="/authorization/:token"
        element={<Authorization />}
      />
      <Route
        path="/logout"
        element={<Logout sso={true} />}
      />
      <Route
        path="/logout/callback"
        element={<Logout />}
      />
      <Route
        path="*"
        element={<ErrorPage />}
      />
    </Routes>
  );
};

export default AppRoutes;
