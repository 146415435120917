import { useContext } from "react";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import useIframeResize from "src/hooks/useIframeResize";

export default function MetabaseEmbedProgrammaticFiltering({ content }) {
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const { maxWidth } = useIframeResize({ workspaceDetails, site });

  return (
    <>
      <div
        className={classNames(
          "relative h-full w-full",
          menuType === "horizontal" || menuType === "vertical" ? "" : "", // for horizontal h-screen minus the top menu bar (x2)
        )}>
        {content?.embedUrl && (
          <div
            className="mx-auto h-full w-full  overflow-auto"
            style={{ maxWidth }}>
            <iframe
              id="iframe"
              title="Domo Embed"
              src={content?.embedUrl}
              width="100%"
              height="100%"></iframe>
          </div>
        )}
      </div>
    </>
  );
}
