import { ArrowUpTrayIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useMemo, useRef } from "react";
import { toast } from "react-toastify";
import Button from "src/components/form/Button";
import { fileExtensions } from "src/helpers/image";

const FileUpload = ({ content = null, selectedFiles = [], onHandleChange = () => {} }) => {
  const fileInputRef = useRef(null);

  const getAcceptString = useMemo(() => {
    return content?.file_types
      .map((type) => fileExtensions[type])
      .flat()
      .map((ext) => `.${ext}`)
      .join(", ");
  }, [content?.file_types]);

  const onChange = (e) => {
    if (parseInt(content?.file_number) >= e.target.files?.length + (selectedFiles?.length || 0)) {
      const maxFileSizeBytes = content?.file_size * 1024 * 1024; // Convert MB to bytes

      const extensions = content?.file_types.reduce((acc, type) => {
        return acc.concat(fileExtensions[type] || []);
      }, []);

      let files = [];
      for (const file of e.target.files) {
        const fileExtension = file?.name?.split(".").pop().toLowerCase();
        if (!extensions.find((ext) => fileExtension === ext)) {
          toast.error(`${file?.name} is not a valid file.`);
          break;
        } else if (file.size > maxFileSizeBytes) {
          toast.error(`${file?.name} exceeds the maximum size of ${content?.file_size} MB.`);
          break;
        } else {
          files.push(file);
        }
      }

      if (files?.length === e.target.files?.length) {
        onHandleChange(content?._id, "value", files, content?.question_type);
      }
    } else {
      toast.error(`You can choose maximum ${content?.file_number} number of file(s).`);
      if (fileInputRef.current) fileInputRef.current.value = "";
    }
  };

  const onRemove = (i) => {
    const files = selectedFiles?.filter((_, index) => index !== i);
    onHandleChange(content?._id, "value", files);
  };

  return (
    <div className="relative space-y-4">
      <div className="space-y-1">
        <div className="text-base font-semibold text-gray-700">
          {content?.name} {content?.validation?.required && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
        </div>
        <div className="text-sm font-medium text-gray-400">{`Upload ${content?.file_number > 1 ? "up to" : ""} ${content?.file_number} supported file${content?.file_number > 1 ? "s" : ""}: ${getAcceptString}. Max ${content?.file_size} MB${content?.file_number > 1 ? " per file" : ""}.`}</div>
      </div>
      {selectedFiles && selectedFiles?.length > 0 && (
        <div className="item-center flex flex-wrap gap-2">
          {selectedFiles?.map((val, index) => (
            <div
              key={`${content?._id}-${index}`}
              className="relative flex items-center gap-2 rounded-md border border-gray-200 px-2 py-2 transition-all duration-200 hover:border-gray-300 hover:bg-gray-50">
              <PhotoIcon className="h-5 w-5 stroke-[0.8]" />
              <span className="text-sm font-medium text-gray-700">{val?.name}</span>
              <Button
                buttonHasLink={false}
                buttonIcon={XMarkIcon}
                buttonIconPosition={"left"}
                buttonIconClass={""}
                buttonClasses={"!h-6 !p-0 !aspect-square !bg-transparent !text-gray-400"}
                buttonFunction={() => onRemove(index)}
              />
            </div>
          ))}
        </div>
      )}
      {parseInt(content?.file_number) > (selectedFiles?.length || 0) && (
        <div className="flex">
          <Button
            className="relative overflow-hidden"
            buttonLabel={
              <>
                <span className="text-sm">Add file</span>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept={getAcceptString}
                  className="absolute right-0 top-0 z-10 h-full w-96 cursor-pointer opacity-0"
                  multiple={content?.file_number > 1 ? true : false}
                  onChange={onChange}
                />
              </>
            }
            buttonIcon={ArrowUpTrayIcon}
            buttonIconPosition="left"
          />
        </div>
      )}
    </div>
  );
};

export default FileUpload;
