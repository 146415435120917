import { ArrowTopRightOnSquareIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import SafariBrowserLogo from "src/assets/images/safari_browser_logo.png";

export default function BrowserEmbedError({ browser }) {
  return (
    <div className="flex items-center justify-center gap-x-5">
      <div className="flex w-full items-center gap-x-4 rounded bg-white py-4 pl-4 pr-6">
        {browser === "Safari" ? (
          <img
            className="h-20 w-20 opacity-70"
            alt="safari browser"
            src={SafariBrowserLogo}
          />
        ) : (
          <>
            <ExclamationCircleIcon className="h-20 w-20 stroke-blue-800 stroke-2" />
          </>
        )}
        <div className="flex flex-col">
          {/* <p>{`Third-party cookies in ${browser}: ${enabled ? "Enabled" : "Disabled"}`}</p> */}
          {browser === "Safari" ? (
            <>
              <p className="flex items-center text-2xl font-extrabold text-gray-800">You’re using Safari...</p>
              <p className="flex items-center font-thin text-gray-900">Safari has set 3rd-Party cookies to disabled as your default.</p>
              <p className="flex items-center font-thin text-gray-900">
                <span className="italic">The prefered browser is </span>
                <a
                  className="flex items-center gap-x-1 pl-1 text-blue-500 underline transition-all hover:text-blue-700"
                  href="https://www.google.com/chrome/dr/download/?brand=JSBI&ds_kid=43700079939272283&gad_source=1&gclid=CjwKCAjww_iwBhApEiwAuG6ccBnV6vchH7_R4EBF6qEjcHJX_12SJ4Zeh25PI3F5o4m2qNaGaSnJ6RoCiXMQAvD_BwE&gclsrc=aw.ds">
                  Google Chrome <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                </a>
              </p>
            </>
          ) : (
            <>
              <p className="flex items-center text-2xl font-extrabold text-blue-800 mb-3">Your browser has disabled 3rd-Party cookies</p>
              <p className="flex items-center text-gray-900">
                {browser === "Chrome" ? (
                  <div className="w-full">
                    <li className="text-gray-800">
                      Navigate to <span className="font-medium text-gray-900">chrome://settings/cookies</span> in your browser
                    </li>
                    <li className="text-gray-800">
                      Find and enable <span className="font-medium text-gray-900">Allow third-party cookies</span>
                    </li>
                  </div>
                ) : (
                  <>
                    Learn more about 3rd Party cookies at
                    <a
                      className="flex items-center gap-x-1 px-1 text-blue-500 underline transition-all hover:text-blue-700"
                      href="https://support.microsoft.com/en-us/office/enable-cookies-6b018d22-1d24-43d9-8543-3d35ddb2cb52">
                      {" "}
                      More Information.
                    </a>
                  </>
                )}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
