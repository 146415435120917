import { useContext } from "react";
import { SiteContext } from "src/context/site";
import { WorkspaceContext } from "src/context/workspace";
import { classNames } from "src/helpers/classNames";
import useIframeResize from "src/hooks/useIframeResize";

export default function DomoEmbedProgrammaticFiltering({ content }) {
  const { details: workspaceDetails, menuType } = useContext(WorkspaceContext);
  const { site } = useContext(SiteContext);
  const { maxWidth } = useIframeResize({ workspaceDetails, site });

  // Define the conditional maxWidth value
  const conditionalMaxWidth = content?.embed_type === "EMBED_APP" ? "100%" : maxWidth;

  return (
    <>
      <div className={classNames("relative h-full w-full", menuType === "horizontal" || menuType === "vertical" ? "" : "")}>
        {content?.iframeHtml && (
          <div
            className="mx-auto h-full w-full overflow-auto"
            style={{ maxWidth: conditionalMaxWidth }}
            dangerouslySetInnerHTML={{ __html: content?.iframeHtml }}
          />
        )}
      </div>
    </>
  );
}
