// src/hooks/useBrowserType.js
import { useState, useEffect } from 'react';

const useBrowserType = () => {
  const [browser, setBrowser] = useState(null);

  useEffect(() => {
    const checkBrowser = () => {
      const userAgent = navigator.userAgent;
      if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
        return "Safari";
      } else if (/Chrome/.test(userAgent)) {
        return "Chrome";
      } else if (/Firefox/.test(userAgent)) {
        return "Firefox";
      } else if (/Edg/.test(userAgent)) {
        return "Edge";
      } else {
        return "Other";
      }
    };

    setBrowser(checkBrowser());
  }, []);

  return browser;
};

export default useBrowserType;