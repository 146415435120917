import { classNames } from "src/helpers/classNames";

export default function QuickSightEmbedPlatform({ content }) {
  return (
    <>
      <div className={classNames("relative h-full w-full")}>
        {content && (
          <div className="mx-auto h-full w-full  overflow-auto">
            <iframe
              id="iframe"
              title="QuickSight Embed"
              src={content}
              width="100%"
              height="100%"></iframe>
          </div>
        )}
      </div>
    </>
  );
}
